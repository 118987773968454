.rhap_container {
  box-shadow: none;
  background-color: transparent;
  padding: 0;
  max-width: calc((700px / 2) + 50vw);
}

.rhap_controls-section {
  gap: 0.5rem;
  min-width: calc(50vw - (700px / 2) - 2rem);
}

.with-properties .rhap_controls-section {
  min-width: auto;
}

.rhap_volume-controls {
  justify-content: flex-start;
  gap: 0.5rem;
}

.playback-rate-control {
  background-color: rgb(134, 134, 134);
  font-weight: bold;
  color: white;
  border-radius: 8px;
  min-height: 1.5rem;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 0.4rem;
  font-size: 0.85rem;
  user-select: none;
  cursor: pointer;
}

.rhap_rewind-button, .rhap_forward-button, .rhap_additional-controls {
  display: none;
}

.MuiPopover-root {
  z-index: 999999 !important;
}

.MuiMenu-paper { 
  border-radius: 10px !important;
}

.ProseMirror-wrapper {
  flex: 1;
  max-height: calc(100vh - 130px);
  overflow-y: auto;
}

.ProseMirror {
  min-height: calc(100vh - 170px);
  max-width: calc(700px + 2rem);
  margin: auto;
  outline: none;
  margin-top: 2rem;
  padding: 0 1rem;
}

.ProseMirror p {
  line-height: 1.75;
  transition: color 0.2s ease;
}

.rapid-mode-enabled .ProseMirror p {
  color: #37373747;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.transcript-title {
  font-size: 1.15rem;
  font-weight: 600;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  margin-left: -0.5rem;
  background-color: #e7e7e7;
}

.transcript-title:hover {
  border: 1px solid #007bff;
}

.editor-container {
  display: flex;
  min-height: 100vh;
}

.editor-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: auto;
}

.multi-button {
  display: flex;
  align-items: center;
}

.editor-header button {
  height: 32px;
}

.MuiTextField-root {
  background-color: transparent !important;
}

.color {
  background-color: #007bff;
}

@media screen and (max-width: 1200px) {
  .rhap_volume-bar-area {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .editor-container .editor-content {
    width: 100vw !important;
  }

  .editor-container .editor-properties {
    width: 100vw !important;
  }
}